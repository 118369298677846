:root {
  --independent-color: #AD8A56;
  --enhanced-color: #B4B4B4;
  --professional-color: #AF9500;
  --base-color: #244B69;
}

/* .Toastify__toast-theme--colored.Toastify__toast--warning {
  background: #E0A045 !important;
} */

body, html {
  overflow-x: hidden;
}

.brand-banner {
  clip-path: polygon(0 0, 100% 0, 100% 65%, 0% 100%);
}
.line-height {
  line-height: 59px !important;
}
.line-50 {
  line-height: 50px;
}
@media (max-width: 768px) {
  .line-50 {
    line-height: 35px;
  }
}
@media (max-width: 1023px) {
  .brand-banner {
    clip-path: none;
  }
}
/* @media (max-width: 882px) and (min-width: 640px) {
  .navbar a {
    padding: 8px 3px;
    font-size: 12px;
  }
} */
/* @media (max-width: 640px) and (min-width: 763px) {
  .navbar a {
    margin-left: 2px !important;
    margin-right: 2px !important;
    font-size: 0.75rem !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
} */
@media (max-width: 582px) {
  .faqs {
    font-size: 13px;
    padding-left: 8px;
    padding-right: 8px;
    font-weight: 500;
  }
}
@media (max-width: 1120px) and (min-width: 768px) {
  .extra-padding {
    padding-top: 40%;
  }
}
@media (max-width: 640px) and (min-width: 763px) {
  .nav-bar a {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 0.75rem !important;
    padding-left: 0;
    padding-right: 0;
  }
}
@keyframes mybird1 {
  from {
    transform: translateX(0px);
  }
  to {
    transform: translateX(70px);
  }
}
@keyframes mybird2 {
  /* from {transform: translateX(0px);}
   to {transform: translateX(100px);} */
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(35px);
  }
}
.font-inter {
  font-family: "Inter";
}
.font-poppins {
  font-family: "Poppins";
}
.bird1 {
  animation-name: mybird1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2s;
}
.bird2 {
  animation-name: mybird2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-duration: 2s;
}
/* ******FORM PAGE ONE****** */
input[type="checkbox"]:checked ~ .color1 {
  background-color: var(--base-color);
  color: #fff;
}
input[type="checkbox"]:checked ~ .color2 {
  background-color: #ecbd41;
  color: var(--base-color);
}
/* ******FORM PAGE TWO****** */
input[type="radio"]:checked ~ .color3 {
  background: var(--base-color);
  color: #fff;
}
input[type="radio"]:checked + .pay-price {
  background: var(--professional-color);
}
input[type="radio"]:not(:checked) + .pay-price:hover {
  color: var(--professional-color);
  background-color: #fff;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.alert.success{
  background-color: #D1E7DD;
  border: 1px solid #a5d6c0;
}
.alert.danger{
  background-color: #F8D7DA;
  border: 1px solid #d8969b;
}

.accordion-button.active {
  background-color: var(--base-color);
  color: #fff;
}
.accordion-button.active::before {
  content: "";
  position: absolute;
  border-bottom: 2px solid var(--base-color);
  width: 25%;
  bottom: 0;
  left: var(--pseudo-position, 0);
}
.bg-img{
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-img1{
  background-image: url('../public/images/bgimg1.png');
}
.bg-img2{
  background-image: url('../public/images/bgimg2.png');
}
.bg-img3{
  background-image: url('../public/images/bgimg3.png');
}
.bg-img4{
  background-image: url('../public/images/bgimg4.png');
}

.popup-content:has(.pricingModal) {
  height: 100vh;
  overflow: scroll;
  border-radius: .5rem;
  width: 100% !important;
  border: none !important;
  padding: 0 !important
}

/* CRM TABS */
.tab.active {
  color: #00B67A;
}
.tab.active::before {
  content: "";
  position: absolute;
  border-bottom: 2px solid #00B67A;
  width: 20%;
  bottom: -2px;
  left: var(--pseudo-position, 0);
}

.chat-left-wrapper:after {
  content: "";
  position:absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #EBEFF1;
  transform:rotate(225deg);
  left: -.4rem; 
  top: -.3rem;
}

.chat-right-wrapper:after {
  content: "";
  position:absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #EEF8F4;
  transform:rotate(135deg);
  right: -.4rem; 
  top: -.3rem;
}

.bg-independent {
  background-color: var(--independent-color);
}
.bg-enhanced {
  background-color: var(--enhanced-color);
}
.bg-professional {
  background-color: var(--professional-color);
}

.text-independent {
  color: var(--independent-color);
}
.text-enhanced {
  color: var(--enhanced-color);
}
.text-professional {
  color: var(--professional-color);
}

.border-independent {
  border-color: var(--independent-color);
}
.border-enhanced {
  border-color: var(--enhanced-color);
}
.border-professional {
  border-color: var(--professional-color);
}

.popup-content {
  height: 80%;
  overflow: scroll;
  border-radius: 0.5rem;
  padding: 1rem !important;
}

.rpv-core__page-layer::after {
  box-shadow: none;
}

@media (max-width: 768px) {
  .popup-content {
    width: 90% !important;
    /* height: auto; */
  }
}

label.custom-radio:has(input:checked) {
  background-color: rgb(229 232 235);
}

label.custom-radio > input {
  accent-color: var(--base-color);
}