/* Loader.css */
.loader {
    display: inline-block;
    /* border: 10px dotted; */
    border-radius: 50%;
    border-top: 20px dotted #7FC2C2;
    border-right: 20px dotted #7099BD;
    border-bottom: 20px dotted #244b69;
    border-left: 20px dotted #7099BD;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  